export const checkoutReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_CHECKOUT_MODAL':
      return {
        ...state,
        checkoutIsModalOpen: true
      };
    case 'CLOSE_CHECKOUT_MODAL':
      return {
        ...state,
        checkoutIsModalOpen: false
      };
    case 'SET_PAYMENT_METHOD':
      return {
        ...state,
        paymentMethod: action.payload
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload
      };
    case 'SET_DISCOUNT':
      return {
        ...state,
        discount: action.payload
      };
    case 'SET_DISCOUNT_ERROR':
      return {
        ...state,
        discountError: action.payload
      };
    case 'GENERATE_ORDER':
      return {
        ...state,
        order: action.payload
      };
    case 'TOGGLE_LOADING':
      return {
        ...state,
        loading: !state.loading
      };
    case 'ADD_LOCATIONS':
      return {
        ...state,
        locations: action.payload
      };
    case 'SET_INSTALLMENT_COUNT':
      return {
        ...state,
        installmentCount: action.payload
      };
    case 'CHECK_PAYMENT_STATUS':
      return {
        ...state,
        ischeckingPaymentStatus: !state.ischeckingPaymentStatus
      };
    default:
      return state;
  }
};
