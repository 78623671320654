/**
 * React Facebook Pixel Module
 *
 * @package react-facebook-pixel
 * @author  Zain Sajjad <zsajjad@fetchsky.com>
 */

//

let debug = true;

export const ADD_PAYMENT_INFO = 'AddPaymentInfo';
export const ADD_TO_CART = 'AddToCart';
export const COMPLETE_REGISTRATION = 'CompleteRegistration';
export const INITIATE_CHECKOUT = 'InitiateCheckout';
export const SEARCH = 'Search';
export const VIEW_CONTENT = 'ViewContent';
export const PURCHASE = 'Purchase';

/**
 * Utilities
 */

export const verifyInit = () => {
  if (!window.fbq) {
    // eslint-disable-next-line no-console
    console.warn(
      'Pixel not initialized before using call ReactPixel.init with required params'
    );
  }
  return typeof window.fbq !== 'undefined';
};

//
export const log = (...args) => {
  // eslint-disable-next-line no-console
  //console.info(...['[facebook-pixel]'].concat(args));
};

//
export const defaultOptions = {
  autoConfig: true,
  debug: false
};

export const init = (
  pixelId,
  advancedMatching = {},
  options = defaultOptions
) => {
  /* eslint-disable */
  !(function(f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function() {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  );
  /* eslint-enable */

  if (!pixelId) {
    // eslint-disable-next-line no-console
    console.warn('Please insert pixel id for initializing');
  } else {
    if (options.autoConfig === false) {
      fbq('set', 'autoConfig', false, pixelId); // eslint-disable-line no-undef
    }

    fbq('init', pixelId, advancedMatching); // eslint-disable-line no-undef
    debug = options.debug;
    // console.log(debug, options.debug)
  }
};

export const pageView = () => {
  if (!verifyInit()) {
    return;
  }

  fbq('track', 'PageView'); // eslint-disable-line no-undef

  if (debug) {
    log("called fbq('track', 'PageView');");
  }
};

export const track = (title, data) => {
  if (!verifyInit()) {
    return;
  }

  fbq('track', title, data); // eslint-disable-line no-undef

  if (debug) {
    log(`called fbq('track', '${title}');`);

    if (data) {
      log('with data', data);
    }
  }
};

export const trackCustom = (event, data) => {
  if (!verifyInit()) {
    return;
  }

  fbq('trackCustom', event, data); // eslint-disable-line no-undef

  if (debug) {
    log(`called fbq('trackCustom', '${event}');`);

    if (data) {
      log('with data', data);
    }
  }
};

export const fbq = (...args) => {
  if (!verifyInit()) {
    return;
  }
  window.fbq(...args); // eslint-disable-line no-undef

  if (debug) {
    log(`called fbq('${args.slice(0, 2).join("', '")}')`);

    if (args[2]) {
      log('with data', args[2]);
    }
  }
};
