// Player
export const PLAY_SONG = 'PLAY_SONG';
export const PLAY_ALBUM = 'PLAY_ALBUM';
export const TOGGLE_PLAY_PAUSE = 'TOGGLE_PLAY_PAUSE';
export const CLOSE_PLAYER = 'CLOSE_PLAYER';
export const OPEN_PLAYER_MODAL = 'OPEN_PLAYER_MODAL';
export const CLOSE_PLAYER_MODAL = 'CLOSE_PLAYER_MODAL';
export const SET_SEEKING = 'SET_SEEKING';
export const SET_BUFFERING = 'SET_BUFFERING';
export const SET_DURATION = 'SET_DURATION';
export const SET_PLAYED_SECONDS = 'SET_PLAYED_SECONDS';
export const SET_PLAYED = 'SET_PLAYED';

// Event Item, Ebook Item
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_TICKETS = 'SET_TICKETS';
export const SET_SLUG = 'SET_SLUG';
export const SET_EBOOK = 'SET_EBOOK';
export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const FILTER_TICKETS = 'FILTER_TICKETS';
export const CALCULATE_COST = 'CALCULATE_COST';
export const CLEAR_PRICE = 'CLEAR_PRICE';
export const DATA_FIELDS = 'ADDITIONAL_FIELDS';
export const DATA_FIELD_NAMES = 'ADDITIONAL_FIELD_NAMES';

// Cart
export const OPEN_CART = 'OPEN_CART';
export const CLOSE_CART = 'CLOSE_CART';
export const ADD_CART_ITEM = 'ADD_CART_ITEM';
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export const ADD_TO_CART = 'ADD_TO_CART';
export const TOTAL_COST = 'TOTAL_COST';
export const UPDATE_CURRENCY = 'UPDATE_CURRENCY';
export const CLEAR_ITEM_FROM_CART = 'CLEAR_ITEM_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';

// User
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

// Gallery
export const OPEN_GALLERY = 'OPEN_GALLERY';
export const CLOSE_GALLERY = 'CLOSE_GALLERY';

//Tabs
export const SET_TAB_1 = 'SET_TAB_1';
export const SET_TAB_2 = 'SET_TAB_2';
export const SET_TAB = 'SET_TAB';
