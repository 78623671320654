export const ordersReducer = (state, action) => {
  switch (action.type) {
    case 'SET_TICKET_ID':
      return {
        ...state,
        ticketId: action.payload
      };
    case 'TOGGLE_MODAL':
      return {
        ...state,
        isOpen: !state.isOpen
      };
    default:
      return state;
  }
};
