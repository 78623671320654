import theme from 'styled-theming';
import { variables } from 'components/theme';

// Body Theme
export const bodyBackgroundColor = theme('mode', {
  light: variables.colors.white,
  dark: variables.colors.black
});

// Card Theme
export const cardWrapperBackgroundColor = theme('mode', {
  light: variables.colors.white,
  dark: variables.colors.black
});

export const cardWrapperBorderColor = theme('mode', {
  light: variables.colors.grey13,
  dark: variables.colors.black
});

export const cardButtonBorderColor = theme('mode', {
  light: variables.colors.grey13,
  dark: variables.colors.black
});

export const cardBackgroundColor = theme('mode', {
  light: variables.colors.white,
  dark: variables.colors.black
});

export const LiveStreamTitle = theme('mode', {
  light: variables.colors.blue3,
  dark: variables.colors.white
});
export const LiveStreamLink = theme('mode', {
  light: variables.colors.blue1,
  dark: variables.colors.white
});

export const cardTitleColor = theme('mode', {
  light: variables.colors.grey5,
  dark: variables.colors.white
});

export const SVGColors = theme('mode', {
  light: variables.colors.white,
  dark: variables.colors.white
});

export const mainCardTextColor = theme('mode', {
  light: variables.colors.grey3,
  dark: variables.colors.white
});

export const cardTextColor = theme('mode', {
  light: variables.colors.grey5,
  dark: variables.colors.white
});

export const cardBorderColor = theme('mode', {
  light: variables.colors.grey1,
  dark: variables.colors.black
});

// Tabs Theme
export const tabsBorderColor = theme('mode', {
  light: variables.colors.gray,
  dark: variables.colors.black
});

export const tabsBackgroundColor = theme('mode', {
  light: variables.colors.grey18,
  dark: variables.colors.black
});

export const tabsTextColor = theme('mode', {
  light: variables.colors.grey4,
  dark: variables.colors.white
});

// Dropdown Theme
export const dropdownBackgroundColor = theme('mode', {
  light: variables.colors.grey18,
  dark: variables.colors.black
});

export const dropdownTextColor = theme('mode', {
  light: variables.colors.grey22,
  dark: variables.colors.white
});

// Carousel Theme
export const carouselBackgroundColor = theme('mode', {
  light: variables.colors.grey12,
  dark: variables.colors.black
});

export const carouselTitleColor = theme('mode', {
  light: variables.colors.grey16,
  dark: variables.colors.white
});

export const carouselTextColor = theme('mode', {
  light: variables.colors.grey16,
  dark: variables.colors.white
});

export const carouselDateBackgroundColor = theme('mode', {
  light: variables.colors.white,
  dark: variables.colors.grey21
});

// Search Theme
export const searchBackgroundColor = theme('mode', {
  light: variables.colors.white,
  dark: variables.colors.white
});

export const searchTextColor = theme('mode', {
  light: variables.colors.grey16,
  dark: variables.colors.grey16
});

// Loader Theme
export const loaderTextColor = theme('mode', {
  light: variables.colors.grey4,
  dark: variables.colors.white
});

// Item Theme
export const itemAsideBackgroundColor = theme('mode', {
  light: variables.colors.grey12,
  dark: variables.colors.black
});

export const itemTextColor = theme('mode', {
  light: variables.colors.grey16,
  dark: variables.colors.white
});

export const reverseItemTextColor = theme('mode', {
  light: variables.colors.white,
  dark: variables.colors.black
});

export const itemMainBackgroundColor = theme('mode', {
  light: variables.colors.white,
  dark: variables.colors.black
});

export const itemTitleColor = theme('mode', {
  light: variables.colors.grey5,
  dark: variables.colors.white
});

// Button Theme
export const buttonBackgroundColor = theme('mode', {
  light: variables.colors.white,
  dark: variables.colors.black
});

export const buttonBorderColor = theme('mode', {
  light: variables.colors.grey4,
  dark: variables.colors.white
});

// Checkout Theme
export const checkoutLinkColor = theme('mode', {
  light: variables.colors.grey7,
  dark: variables.colors.white
});

// Player Theme
export const playerTextColor = theme('mode', {
  light: variables.colors.grey4,
  dark: variables.colors.white
});

// Login Theme
export const LoginFooterTheme = theme('mode', {
  light: variables.colors.grey4,
  dark: variables.colors.white
});

export const navbarColor = theme('mode', {
  light: variables.colors.yellow6,
  dark: variables.colors.black
});
export const navbarTextColor = theme('mode', {
  light: variables.colors.black,
  dark: variables.colors.white
});
