import { Flex } from 'antd';
import styled from 'styled-components';
import variables from '../../components/theme/variables';
import contentWrapperBackground from 'assets/images/momo/cecile-hournau-FicbOWWbUqQ-unsplash.jpg';

const Wrapper = styled.div`
    height: 100vh;
    box-sizing: border-box;
    ${variables.fonts.MTNBrighterSans}
    background: ${variables.colors.MOMO_BLUE};

    .ant-input,
    .ant-input-affix-wrapper {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        ${variables.fonts.MTNBrighterSans}
        color: ${variables.colors.MOMO_WHITE};
        border-bottom: 1.2px solid ${variables.colors.MOMO_WHITE};

        /* Edge 12-18 */
        ::-ms-input-placeholder { 
            color: ${variables.colors.MOMO_WHITE};
        }

        ::placeholder {
            color: ${variables.colors.MOMO_WHITE};
        }
    }

    .ant-input-prefix {
        padding: 8px;
    }

    .ant-input-prefix svg {
        width: 24px;
        color: ${variables.colors.MOMO_WHITE};
    }

    .ant-form-item-explain-error {
        color: ${variables.colors.MOMO_YELLOW};
    }

    .ant-input-password-icon svg {
        color: ${variables.colors.MOMO_WHITE};
    }
    
    .ant-checkbox-wrapper {
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;

        ${variables.fonts.MTNBrighterSans}
        color: ${variables.colors.MOMO_WHITE};
    }

    .react-tel-input input[type=tel] {
        width: 100%;
        border: none;
        background: none !important;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        ${variables.fonts.MTNBrighterSans}
        color: ${variables.colors.MOMO_WHITE};
        border-bottom: 1.2px solid ${variables.colors.MOMO_WHITE};
    }

    .react-tel-input .flag-dropdown,
    .react-tel-input .flag-dropdown.open-dropdown,
    .react-tel-input .flag-dropdown .selected-flag {
        border: none;
        background: none !important;
        ${variables.fonts.MTNBrighterSans}
    }
    
    .react-tel-input .flag-dropdown.open-dropdown .country-list {
        border-radius: 6px;
        ${variables.fonts.MTNBrighterSans}
        background: ${variables.colors.MOMO_WHITE};
    }


    .forgot-password,
    .terms-and-conditions {
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;

        ${variables.fonts.MTNBrighterSans}
        color: ${variables.colors.MOMO_WHITE};
    }
    
    .terms-and-conditions {
        color: ${variables.colors.MOMO_YELLOW};
    }

    #momo-ticketing-logo-wrapper {
        display:inline-flex;
        
        img {
            padding: 8px 16px;
            border-radius: 6px;
            background: ${variables.colors.MOMO_BLUE};
        }
    }
`;

export const FormWrapper = styled(Flex)`
  height: 100%;
  padding: 64px;
  background: ${variables.colors.MOMO_BLUE};
`;

export const FormTitle = styled.h1`
  ${variables.fonts.MTNBrighterSansExtraBold}
  font-size: 32px;
  line-height: 41.6px;
  color: ${variables.colors.MOMO_WHITE};
`;

export const FormSubtitle = styled.h3`
  ${variables.fonts.MTNBrighterSans}
  font-size: 16px;
  line-height: 20.8px;
  color: ${variables.colors.MOMO_WHITE};

  a {
    ${variables.fonts.MTNBrighterSansBold}
    color: ${variables.colors.MOMO_YELLOW};
  }
`;

export const FormLabel = styled.label`
    ${variables.fonts.MTNBrighterSans}
    color: ${variables.colors.MOMO_WHITE};
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
`;

export const FormFooter = styled.h3`
  ${variables.fonts.MTNBrighterSans}
  font-size: 16px;
  line-height: 20.8px;
  color: ${variables.colors.MOMO_WHITE};

  a {
    ${variables.fonts.MTNBrighterSansBold}
    color: ${variables.colors.MOMO_YELLOW};
  }
`;

export const ContentWrapper = styled.div`
  height: 100%;
  padding: 64px;
  display: flex;
  flex-direction: column;

  background-size: cover;
  background-position: left center;
  background-image: url(${contentWrapperBackground});
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .auth-view-dots {
    bottom: -64px;
    display: flex;
    justify-content: flex-start;
  }
`;

export const ContentTitle = styled.h1`
    ${variables.fonts.MTNBrighterSansExtraBold}
    color: ${variables.colors.MOMO_WHITE};
    line-height: 62px;
    font-size: 48px;
`;

export const ContentDescription = styled.p`
    ${variables.fonts.MTNBrighterSans}
    color: ${variables.colors.MOMO_WHITE};
    line-height: 22px;
    font-size: 14px;
`;

export const ContentFooter = styled.div`
    ${variables.fonts.MTNBrighterSans}
    color: ${variables.colors.MOMO_WHITE};

    gap: 52px;
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: space-between;
`;

export default Wrapper;
