import React, { useCallback } from 'react';
import toaster from 'toasted-notes';
import styled, { css } from 'styled-components';

import { ReactComponent as SuccessTick } from 'assets/icons/check_circle.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/cancel.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { ReactComponent as Svg } from 'assets/icons/close.svg';
import { ThemeProvider, useTheme } from 'context/theme/themeContext';

const iconVariants = status => {
  switch (status) {
    case 'error':
      return '#ff1f1f';
    case 'warning':
      return '#ffffff';
    default:
      return '#dc3545';
  }
};
const bgVariants = status => {
  switch (status) {
    case 'error':
      return '#ff1f1f';
    case 'warning':
      return '#dd6b20';
    default:
      return 'rgb(24, 177, 55)';
  }
};

const ToastWrapper = styled.div`
  display: flex;
  ${({ status }) => status && `background: ${bgVariants(status)}`};
  margin-top: 14px;
  padding: 14px 21px;
  border-radius: 4px;
  color: white !important;
  ${({ position }) =>
    position?.includes('bottom') &&
    css`
      position: relative;
      bottom: 28px;
      left: 21px;

      @media screen and (max-width: 768px) {
        left: 0px;
      }
    `};
`;

const ToastIcon = styled.div`
  margin-right: 7px;
  position: relative;
  top: -3px;

  .icon {
    ${({ status }) => status && `fill: ${iconVariants(status)}`}
  }
`;

const ToastBody = styled.div`
  text-align: left;
`;

const ToastTitle = styled.h4`
  line-height: 1;
  margin-bottom: 7px;
`;

const ToastDescription = styled.p`
  line-height: 1.5;
`;

const CloseButton = styled.button`
  align-self: flex-start;
  background: transparent;
  border: none;
`;

const Close = styled(Svg)`
  width: 18px;
  height: 18px;
  fill: #fff;
`;

function Toast(props) {
  const { title, description, onClose, isClosable, status, position } = props;

  function handleStatus() {
    switch (status) {
      case 'error':
        return <ErrorIcon />;
      case 'success':
        return <SuccessTick />;
      case 'warning':
        return <WarningIcon />;
      default:
        return null;
    }
  }

  return (
    <ToastWrapper position={position} {...{ status }}>
      <ToastIcon {...{ status }}>{handleStatus()}</ToastIcon>
      <ToastBody>
        {title && <ToastTitle>{title}</ToastTitle>}
        {description && <ToastDescription>{description}</ToastDescription>}
      </ToastBody>
      {isClosable && (
        <CloseButton onClick={onClose}>
          <Close />
        </CloseButton>
      )}
    </ToastWrapper>
  );
}

function useToast() {
  const theme = useTheme();

  const notifyFunction = props => {
    const {
      position,
      duration,
      render,
      title,
      description,
      isClosable,
      status
    } = props;

    const options = {
      position: position ? position : 'top',
      duration: duration ? duration : 5000
    };

    if (render) {
      return toaster.notify(
        ({ onClose }) => (
          <ThemeProvider theme={theme}>{render({ onClose })}</ThemeProvider>
        ),
        options
      );
    }

    toaster.notify(
      ({ onClose }) => (
        <ThemeProvider theme={theme}>
          <Toast
            {...{
              onClose,
              title,
              description,
              isClosable,
              status,
              position
            }}
          />
        </ThemeProvider>
      ),
      options
    );
  };

  const notify = useCallback(notifyFunction, [theme]);

  return notify;
}

export default useToast;
