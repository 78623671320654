import { OPEN_GALLERY, CLOSE_GALLERY } from 'context/constants';

export const galleryReducer = (state, action) => {
  switch (action.type) {
    case OPEN_GALLERY:
      return {
        ...state,
        galleryIsOpen: true,
        image: action.payload
      };
    case CLOSE_GALLERY:
      return {
        ...state,
        galleryIsOpen: false
      };
    default:
      return state;
  }
};
