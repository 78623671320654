export const removeItemFromCart = (cartItems, item) =>
  cartItems.filter(cartItem => cartItem.id !== item.id);

export const addItemToCart = (cartItems, item) => {
  if (!cartItems.find(cartItem => cartItem.id === item.id))
    return [...cartItems, item];
  return cartItems.map(cartItem =>
    cartItem.id !== item.id
      ? cartItem
      : {
          ...cartItem,
          currency: item.currency,
          price: item.price,
          quantity:
            cartItem.quantity < item.maxQuantity
              ? cartItem.quantity + 1
              : item.maxQuantity
        }
  );
};

export const updateCartItems = (cartItems, item) => {
  if (!cartItems.find(cartItem => cartItem.id === item.id))
    return [...cartItems, item];
  return cartItems.map(cartItem =>
    cartItem.id !== item.id
      ? cartItem
      : {
          ...cartItem,
          quantity: item.quantity
        }
  );
};

export const reduceItemQuantity = (cartItems, item) => {
  return cartItems.map(cartItem =>
    cartItem.id !== item.id
      ? cartItem
      : {
          ...cartItem,
          currency: item.currency,
          price: item.price,
          quantity:
            cartItem.quantity > item.minQuantity
              ? cartItem.quantity - 1
              : item.minQuantity
        }
  );
};

export const reduceItemQuantityFromCart = (cartItems, item) => {
  const existingItem = cartItems.find(cartItem => cartItem.id === item.id);

  if (existingItem.quantity === 1) {
    return cartItems.filter(cartItem => cartItem.id !== item.id);
  }

  return cartItems.map(cartItem =>
    cartItem.id === item.id
      ? { ...cartItem, quantity: cartItem.quantity - 1 }
      : cartItem
  );
};

export const addCost = (a, b) => a + b.quantity * b.price;

export const filterTicketsByQuantity = tickets =>
  tickets.filter(ticket => ticket.quantity);
