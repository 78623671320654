import React, { createContext, useContext, useState } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

const mode = localStorage.getItem('mode') || 'light';
const themeContext = createContext();

export const useTheme = () => useContext(themeContext);

export const ThemeProvider = ({ children }) => {
  const [themeMode, setThemeMode] = useState(mode);

  const handleToggleTheme = theme => {
    setThemeMode(theme);
    localStorage.setItem('mode', theme);
  };

  const toggleTheme = () => {
    if (themeMode === 'light') {
      handleToggleTheme('dark');
    } else {
      handleToggleTheme('light');
    }
  };

  return (
    <themeContext.Provider value={{ themeMode, toggleTheme }}>
      <StyledThemeProvider theme={{ mode: themeMode }}>
        <>{children}</>
      </StyledThemeProvider>
    </themeContext.Provider>
  );
};
