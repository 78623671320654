import {
  PLAY_SONG,
  TOGGLE_PLAY_PAUSE,
  CLOSE_PLAYER,
  OPEN_PLAYER_MODAL,
  CLOSE_PLAYER_MODAL,
  SET_SEEKING,
  SET_DURATION,
  SET_PLAYED_SECONDS,
  SET_PLAYED,
  SET_BUFFERING,
  PLAY_ALBUM
} from '../constants';

export const playerReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case PLAY_SONG:
      return {
        ...state,
        isPlaying: true,
        played: 0,
        playedSeconds: 0,
        song: payload
      };
    case PLAY_ALBUM:
      return {
        ...state,
        isPlaying: true,
        playlist: payload
      };
    case TOGGLE_PLAY_PAUSE:
      return {
        ...state,
        isPlaying: !state.isPlaying
      };
    case CLOSE_PLAYER:
      return {
        ...state,
        isPlaying: false,
        song: null,
        playlist: []
      };
    case OPEN_PLAYER_MODAL:
      return {
        ...state,
        playerIsOpen: true
      };
    case CLOSE_PLAYER_MODAL:
      return {
        ...state,
        playerIsOpen: false
      };
    case SET_SEEKING:
      return {
        ...state,
        seeking: payload
      };
    case SET_BUFFERING:
      return {
        ...state,
        isBuffering: payload
      };
    case SET_DURATION:
      return {
        ...state,
        duration: payload
      };
    case SET_PLAYED_SECONDS:
      return {
        ...state,
        playedSeconds: payload
      };
    case SET_PLAYED:
      return {
        ...state,
        played: payload
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: !state.loading
      };
    case 'SET_INFO':
      return {
        ...state,
        info: payload
      };
    case 'SET_MUSIC_CURRENCY':
      return {
        ...state,
        musicCurrency: action.payload
      };
    case 'SET_ALBUM_PRICES':
      return {
        ...state,
        albumPrices: action.payload
      };
    default:
      return state;
  }
};
