import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from 'assets/images/momo/momo-logo-from-mtn.png';

export const Logo = () => {
  return (
    <NavLink to="/" id="momo-ticketing-logo-wrapper">
      <img src={logo} width="auto" height={72} alt="Momo Ticketing" />
    </NavLink>
  );
};
