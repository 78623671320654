import { LOGIN_USER, LOGOUT_USER } from 'context/constants';

export const userReducer = (state, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        token: action.payload.token,
        currentUser: action.payload.user,
        isAuthenticated: true
      };
    case LOGOUT_USER:
      return {
        ...state,
        currentUser: null,
        isAuthenticated: false
      };
    case 'REDIRECT_USER':
      return {
        ...state,
        redirectLink: action.payload
      };
    default:
      return state;
  }
};
