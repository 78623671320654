import { playerReducer } from 'context/player/reducer';
import { eventItemReducer } from './eventItem/reducer';
import { cartReducer } from './cart/reducer';
import { userReducer } from './user/reducer';
import { ebookItemReducer } from './ebookItem/reducer';
import { checkoutReducer } from './checkout/reducer';
import { galleryReducer } from './gallery/reducer';
import { ordersReducer } from './orders/reducer';
import { tabsReducer } from './tabs/reducer';
import { modalReducer } from './modal/reducer';

export const rootReducer = (state, action) => {
  const {
    player,
    eventItem,
    cart,
    user,
    ebookItem,
    checkout,
    gallery,
    orders,
    tabs,
    modal
  } = state;

  return {
    player: playerReducer(player, action),
    eventItem: eventItemReducer(eventItem, action),
    cart: cartReducer(cart, action),
    user: userReducer(user, action),
    ebookItem: ebookItemReducer(ebookItem, action),
    checkout: checkoutReducer(checkout, action),
    gallery: galleryReducer(gallery, action),
    orders: ordersReducer(orders, action),
    tabs: tabsReducer(tabs, action),
    modal: modalReducer(modal, action)
  };
};
