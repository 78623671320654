/*
 * Tracker
 */

import { FACEBOOK_PIXEL_ID } from 'constants.js';
import * as FbPixel from './facebookPixelUtil';
import { addCost } from './cartUtil';

export const initTracking = () => {
  FbPixel.init(FACEBOOK_PIXEL_ID, null, {
    autoConfig: true,
    debug: process.env.NODE_ENV !== 'production'
  });
};

/**
 * @param item
 */
export const trackPurchase = item => {
  try {
    const shopItem = {
      currency: item.currency || 'KES',
      value: item.price * item.quantity,
      num_items: item.quantity,
      content_type: item.typeName,
      content_name: item.name
    };
    FbPixel.track(FbPixel.PURCHASE, shopItem);
  } catch (e) {}
};

/**
 * Track Purchases
 * @param items
 */
export const trackPurchases = items => {
  items.map(item => trackPurchase(item));
};

/**
 * Track registrations
 */
export const trackCompleteRegistration = () => {
  FbPixel.track(FbPixel.COMPLETE_REGISTRATION);
};

/**
 * Track ad payment information
 */
export const trackAddPaymentInfo = () => {
  FbPixel.track(FbPixel.ADD_PAYMENT_INFO);
};

/**
 * Track when someone moves to the checkout page
 */
export const trackInitiateCheckout = items => {
  const checkoutAggregate = {
    content_category: items[0].type,
    currency: items[0].currency || 'KES',
    num_items: items.length,
    value: items.reduce(addCost, 0)
  };
  FbPixel.track(FbPixel.INITIATE_CHECKOUT, checkoutAggregate);
};

/**
 * Track search terms
 * @param search
 */
export const trackSearch = search => {
  FbPixel.track(FbPixel.SEARCH, { search_string: search });
};

/**
 * Track when an item is viewed
 * @param item
 */
export const trackViewContent = item => {
  const shopItem = {
    content_id: item.id,
    content_type: item.type,
    content_name: item.name
  };
  FbPixel.track(FbPixel.VIEW_CONTENT, shopItem);
};

export const trackPageView = () => {
  FbPixel.pageView();
};
