import {
  SET_CURRENCY,
  ADD_ITEM,
  CALCULATE_COST,
  SET_TICKETS,
  SET_SLUG,
  FILTER_TICKETS,
  REMOVE_ITEM,
  CLEAR_PRICE
} from 'context/constants';
import {
  addItemToCart,
  filterTicketsByQuantity,
  addCost,
  reduceItemQuantity
} from 'utils/cartUtil';

export const eventItemReducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENCY:
      return {
        ...state,
        currency: action.payload
      };
    case SET_TICKETS:
      return {
        ...state,
        tickets: action.payload
      };
    case ADD_ITEM:
      return {
        ...state,
        tickets: addItemToCart(state.tickets, action.payload)
      };
    case REMOVE_ITEM:
      return {
        ...state,
        tickets: reduceItemQuantity(state.tickets, action.payload)
      };
    case SET_SLUG:
      return {
        ...state,
        slug: action.payload
      };
    case FILTER_TICKETS:
      return {
        ...state,
        filteredTickets: filterTicketsByQuantity(state.tickets)
      };
    case CALCULATE_COST:
      return {
        ...state,
        total: state.filteredTickets.reduce(addCost, 0)
      };
    case CLEAR_PRICE:
      return {
        ...state,
        total: 0
      };
    case 'CLEAR_IS_FREE':
      return {
        ...state,
        isFree: false
      };
    case 'SET_IS_FREE':
      return {
        ...state,
        isFree: action.payload
      };
    default:
      return state;
  }
};
