import { variables } from './variables';

// Common styled
export const commonStyles = {
  'border-width': `1px`,
  'border-style': 'solid',
  'border-radius': `3px`,
  transition: 'all .3s ease-in-out'
};

// props styles
export const inputStyles = {
  default: {
    ...commonStyles,
    'border-color': variables.colors.grey4,
    color: variables.colors.grey4,

    focus: {
      'border-color': variables.colors.grey4
    }
  },

  error: {
    focus: {
      'border-color': 'red',
      'border-width': `0.5px`,
      'border-style': 'solid'
    }
  }
};

export const errorStyles = {
  border: '1px solid red'
};

export default inputStyles;
