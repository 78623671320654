export const tabsReducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case 'SET_TAB':
      return {
        ...state,
        tabIndex: payload
      };
    case 'SET_PROFILE_TAB':
      return {
        ...state,
        profileTabIndex: payload
      };
    case 'SET_TERMS_TAB':
      return {
        ...state,
        termsIndex: payload
      };
    default:
      return state;
  }
};
