import {
  OPEN_CART,
  ADD_CART_ITEM,
  REMOVE_CART_ITEM,
  CLOSE_CART,
  ADD_TO_CART,
  TOTAL_COST,
  UPDATE_CURRENCY,
  CLEAR_ITEM_FROM_CART,
  CLEAR_CART
} from 'context/constants';
import {
  updateCartItems,
  removeItemFromCart,
  addCost,
  addItemToCart,
  reduceItemQuantityFromCart
} from 'utils/cartUtil';

export const cartReducer = (state, action) => {
  switch (action.type) {
    case OPEN_CART:
      return {
        ...state,
        cartIsOpen: true
      };
    case CLOSE_CART:
      return {
        ...state,
        cartIsOpen: false
      };
    case ADD_CART_ITEM:
      return {
        ...state,
        items: addItemToCart(state.items, action.payload)
      };
    case REMOVE_CART_ITEM:
      return {
        ...state,
        items: reduceItemQuantityFromCart(state.items, action.payload)
      };
    case ADD_TO_CART:
      return {
        ...state,
        items: updateCartItems(state.items, action.payload)
      };
    case UPDATE_CURRENCY:
      return {
        ...state,
        currencyValue: action.payload
      };
    case TOTAL_COST:
      return {
        ...state,
        total: state.items.reduce(addCost, 0)
      };
    case CLEAR_ITEM_FROM_CART:
      return {
        ...state,
        items: removeItemFromCart(state.items, action.payload)
      };
    case CLEAR_CART:
      return {
        ...state,
        items: []
      };
    default:
      return state;
  }
};
