export const modalReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        isOpen: true
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        isOpen: false
      };
    case 'OPEN_SEARCH_MODAL':
      return {
        ...state,
        searchModal: true
      };
    case 'CLOSE_SEARCH_MODAL':
      return {
        ...state,
        searchModal: false
      };
    default:
      return state;
  }
};
