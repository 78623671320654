import React, { createContext, useContext, useReducer } from 'react';
import { rootReducer } from './rootReducer';

const user = localStorage.user ? JSON.parse(localStorage.user) : null;
const token = localStorage.userToken
  ? JSON.parse(localStorage.userToken)
  : null;
const items = sessionStorage.items ? JSON.parse(sessionStorage.items) : [];
const promoItems = sessionStorage.promoItems
  ? JSON.parse(sessionStorage.promoItems)
  : [];
const total = sessionStorage.total ? JSON.parse(sessionStorage.total) : 0;
const isFree = sessionStorage.isFree
  ? JSON.parse(sessionStorage.isFree)
  : false;
const currencyValue = sessionStorage.currencyValue
  ? JSON.parse(sessionStorage.currencyValue)
  : 'KES';
const tabIndex = sessionStorage.tabIndex
  ? JSON.parse(sessionStorage.tabIndex)
  : 1;
const profileTabIndex = sessionStorage.profileTabIndex
  ? JSON.parse(sessionStorage.profileTabIndex)
  : 0;
const termsIndex = sessionStorage.termsIndex
  ? JSON.parse(sessionStorage.termsIndex)
  : 0;

const store = createContext();

const initialState = {
  player: {
    song: null,
    isBuffering: false,
    isPlaying: false,
    duration: 0,
    playedSeconds: 0,
    played: 0,
    seeking: false,
    playerIsOpen: false,
    playlist: [],
    loading: false,
    info: {}
  },
  eventItem: {
    currency: null,
    tickets: [],
    filteredTickets: [],
    slug: '',
    total: 0,
    isFree
  },
  cart: {
    cartIsOpen: false,
    items,
    total,
    finalTotal: 0,
    currencyValue
  },
  user: {
    token,
    currentUser: user,
    isAuthenticated: user !== null,
    redirectLink: ''
  },
  ebookItem: {
    ebook: [],
    total: 0,
    currency: 'KES'
  },
  musicItem: {
    songs: 1,
    musicCurrency: null,
    albumPrices: []
  },
  checkout: {
    checkoutIsModalOpen: false,
    paymentMethod: '',
    loading: false,
    ischeckingPaymentStatus: false,
    locations: [],
    discount: {},
    discountError: '',
    order: {},
    error: {},
    installmentCount: 1,
    promoItems
  },
  gallery: {
    galleryIsOpen: false,
    image: {}
  },
  orders: {
    ticketId: ''
  },
  tabs: {
    tabIndex,
    profileTabIndex,
    termsIndex
  },
  modal: {
    isOpen: false,
    searchModal: false
  }
};

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  return (
    <store.Provider value={{ state, dispatch }}>{children}</store.Provider>
  );
};

export const useStateValue = () => useContext(store);
