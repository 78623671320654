import {
  ADD_ITEM,
  REMOVE_ITEM,
  CALCULATE_COST,
  CLEAR_PRICE,
  SET_EBOOK,
  SET_CURRENCY
} from 'context/constants';

import { addItemToCart, addCost, reduceItemQuantity } from 'utils/cartUtil';

export const ebookItemReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;

    case ADD_ITEM:
      return {
        ...state,
        ebook: addItemToCart(state.ebook, action.payload)
      };

    case REMOVE_ITEM:
      return {
        ...state,
        ebook: reduceItemQuantity(state.ebook, action.payload)
      };

    case CALCULATE_COST:
      return {
        ...state,
        total: state.ebook.reduce(addCost, 0)
      };

    case CLEAR_PRICE:
      return {
        ...state,
        total: 0
      };

    case SET_EBOOK:
      return {
        ...state,
        ebook: action.payload
      };

    case SET_CURRENCY:
      return {
        ...state,
        currency: action.payload
      };
  }
};
