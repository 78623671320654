export const variables = {
  colors: {
    white: '#ffffff',
    yellow: '#FAEA29',
    yellow1: '#FFEC00',
    yellow4: '#D9C331',
    yellow5: '#d9c331',
    yellow6: '#fecb02',
    red1: '#f52828',
    red2: '#B71414',
    red3: '#FF5050',
    red4: '#FFE4E4',
    red5: '#FF0000',
    blue1: '#1f8de1',
    blue2: '#1574F2',
    blue3: '#192646',
    gray: '#E9E9E9',
    grey1: '#F6F3EE',
    grey2: '#AEAEAE',
    grey3: '#919191',
    grey4: '#31383E',
    gray7: '#8A8A8A',
    grey5: '#0F1014',
    grey6: '#1d1d1d',
    grey7: '#707070',
    grey8: '#b8b8b8',
    grey9: '#03133e',
    grey10: '#BBBBBB',
    grey11: '#F9F9F9',
    grey12: '#F6F6F6',
    grey13: '#aaaaaa',
    grey14: '#e3e3e3',
    grey15: '#201C1C',
    grey16: '#0E0F13',
    grey17: '#989c9f',
    grey18: '#f8f9fa',
    grey19: '#F8F8F8',
    grey20: '#373E44',
    grey21: '#474C51',
    grey22: '#333',
    grey23: '#201D1D',
    brown1: '#B71414', // This is not brown 😢
    brown2: '#d9c331',
    black: '#000',

    // MOMO COLORS
    MOMO_BLUE: '#004F71',
    MOMO_GREY: '#6A6A6A',
    MOMO_WHITE: '#FFFFFF',
    MOMO_YELLOW: '#FFCB05',

    MOMO_GREY_TINT: '#F3F3F3',
    MOMO_BLUE_TINT: '#F2F4FF',
    MOMO_YELLOW_TINT: '#FFFAE6'
  },

  fonts: {
    MTNBrighterSansExtraLight: {
      fontFamily: 'MTNBrighterSans, sans-serif',
      fontWeight: 200
    },
    MTNBrighterSansLight: {
      fontFamily: 'MTNBrighterSans, sans-serif',
      fontWeight: 300
    },
    MTNBrighterSans: {
      fontFamily: 'MTNBrighterSans, sans-serif',
      fontWeight: 400
    },
    MTNBrighterSansMedium: {
      fontFamily: 'MTNBrighterSans, sans-serif',
      fontWeight: 500
    },
    MTNBrighterSansBold: {
      fontFamily: 'MTNBrighterSans, sans-serif',
      fontWeight: 'bold'
    },
    MTNBrighterSansExtraBold: {
      fontFamily: 'MTNBrighterSans, sans-serif',
      fontWeight: 800
    }
  }
};

export default variables;
