import Wrapper, {
  Content,
  FormTitle,
  FormLabel,
  FormFooter,
  FormWrapper,
  ContentTitle,
  FormSubtitle,
  ContentFooter,
  ContentWrapper,
  ContentDescription
} from '../elements';
import axios from 'axios';
import { Logo } from '../brand';
import React, { useState } from 'react';
import useToast from 'components/toast';
import Button from 'components/momo/button';
import { useStateValue } from 'context/store';
import { NavLink, Redirect, withRouter } from 'react-router-dom';
import { Row, Col, Flex, Grid, Form, Input, Carousel, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as Ticket } from '../../../assets/icons/carbon_ticket.svg';
import { ReactComponent as CheckCircle } from '../../../assets/icons/check_circle.svg';

const VerifyOTPPage = props => {
  const toast = useToast();
  const { history, match } = props;
  const {
    state: { user }
  } = useStateValue();
  const [form] = Form.useForm();
  const { lg, xl, xxl } = Grid.useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { isAuthenticated } = user;

  const handleFormSubmission = async values => {
    setLoading(true);
    const {
      params: { id }
    } = match;

    axios
      .post(`${process.env.REACT_APP_API_URL}/users/user/${id}/verify_otp/`, {
        ...values
      })
      .then(({ data }) => {
        toast({
          duration: 4000,
          isClosable: true,
          title: 'Success!',
          status: 'success',
          position: 'bottom-left',
          description: 'Registration successful!'
        });
      })
      .then(() => props.history.push('/login'))
      .catch(({ response: { data } }) => {
        console.debug('errors', data);

        toast({
          title: 'Error!',
          status: 'error',
          description: data?.message[0] ?? 'Sorry, request failed'
        });
      })
      .finally(() => {
        setLoading(false);
        form.setFields({ ...values, password: '', confirmPassword: '' });
      });
  };

  const resendOTPHandler = () => {
    setIsLoading(true);
    const {
      params: { id }
    } = match;
    axios
      .post(`${process.env.REACT_APP_API_URL}/users/user/${id}/request_otp/`)
      .then(() => {
        toast({
          title: 'Success!',
          description: 'OTP sent successfully',
          status: 'success',
          duration: 4000,
          isClosable: true,
          position: 'top'
        });
        setIsLoading(false);
      })
      .catch(({ response: { data } }) => {
        toast({
          title: 'Error!',
          description: data?.message[0] ?? 'Sorry, request failed',
          status: 'error'
        });
        setIsLoading(false);
      });
  };

  if (isAuthenticated) return <Redirect to="/" />;

  return (
    <Wrapper>
      <Row wrap style={{ height: '100%' }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={14} xxl={15}>
          {lg | xl | xxl ? (
            <ContentWrapper>
              <Logo />

              <Content>
                <Carousel effect="fade" dots={{ className: 'auth-view-dots' }}>
                  <div>
                    <Flex vertical gap="large">
                      <Flex vertical gap="middle">
                        <ContentTitle>
                          Join the MoMo Events Community
                        </ContentTitle>

                        <ContentDescription>
                          With a buyer account you get to checkout faster. You
                          will also always have access to your event tickets,
                          change the ticket names and much more.
                        </ContentDescription>
                      </Flex>

                      <ContentFooter>
                        <Button
                          size="large"
                          color="blue"
                          shape="round"
                          background="yellow"
                          onClick={() => history.push('/sign-up')}
                        >
                          Join Now
                        </Button>

                        <Flex gap="middle" align="center">
                          <Ticket />
                          <span>+250K tickets sold</span>
                        </Flex>
                      </ContentFooter>
                    </Flex>
                  </div>
                </Carousel>
              </Content>
            </ContentWrapper>
          ) : null}
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={9}>
          <FormWrapper vertical gap="large" justify="center">
            {lg | xl | xxl ? null : <Logo />}

            <FormTitle>OTP Verification</FormTitle>

            <FormSubtitle>Enter OTP sent to your email</FormSubtitle>

            <Form
              form={form}
              size="large"
              layout="vertical"
              onFinish={handleFormSubmission}
            >
              <Form.Item
                name="otp"
                label={<FormLabel>Code</FormLabel>}
                rules={[
                  {
                    required: true,
                    message: 'Code is invalid!'
                  }
                ]}
              >
                <Input
                  size="large"
                  variant="borderless"
                  prefix={<CheckCircle width={16} />}
                  placeholder="Enter your code"
                />
              </Form.Item>

              <Row>
                <Col sm={24} md={18}>
                  <Form.Item>
                    <FormFooter>
                      Back to the{' '}
                      <NavLink to={'/login'} className="back-to-login">
                        login page
                      </NavLink>
                    </FormFooter>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <FormFooter>
                      <button
                        type="button"
                        style={{
                          border: 'none',
                          background: 'none',
                          color: 'rgb(255, 203, 5)'
                        }}
                        onClick={resendOTPHandler}
                      >
                        {isLoading ? (
                          <Spin
                            indicator={
                              <LoadingOutlined
                                spin
                                style={{
                                  color: 'rgb(255, 203, 5)'
                                }}
                              />
                            }
                          />
                        ) : (
                          ''
                        )}{' '}
                        Resend OTP
                      </button>
                    </FormFooter>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Button
                  block
                  size="large"
                  color="blue"
                  shape="round"
                  htmlType="submit"
                  loading={loading}
                  background="yellow"
                  style={{ margin: '24px 0' }}
                >
                  {loading ? 'Verifying OTP...' : 'VERIFY OTP'}
                </Button>
              </Form.Item>
            </Form>
          </FormWrapper>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default withRouter(VerifyOTPPage);
